<template>
  <div v-if="$apollo.loading">Loading...</div>
  <v-app v-else style="background: none;">
    <div class="container">
      <md-card class="bg-secondary ">
        <md-card-content>
          <h1 class="text-white">Product Data Management- Catalogs</h1>
          <p class="text-wrap text-white">
            Assortments can be created by manually adding products or by Tag
            Rules.<br />

            Once your Category Tree is built, you can allow companies
            permissions below.
          </p>

          <v-btn class="ma-2" color="orange darken-2" dark href="/pim/channels">
            <v-icon dark left> mdi-arrow-left </v-icon>Back
          </v-btn>
        </md-card-content>
      </md-card>

      <div class="row">
        <div class="col-lg-5">
          <v-card style="min-height: 600px; " class="h-100">
            <v-toolbar flat color="indigo" dark>
              <v-toolbar-title>{{ channel.name }} Catalog </v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>

            <v-treeview
              :items="items"
              activatable
              item-key="id"
              color="primary"
              @update:active="onUpdate"
            >
              <template #prepend="{ item, open }">
                <v-icon v-if="!item.file">
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
                <v-icon v-else>
                  {{ files[item.file] }}
                </v-icon>
              </template>
            </v-treeview>
          </v-card>
        </div>
        <div class="col-lg-7">
          <v-form v-model="editformvalid" @submit.prevent="editCategory">
            <v-card
              v-if="activatedId[0] == undefined"
              style="min-height: 600px;"
            >
              <v-card-text>
                <div class="row">
                  <div class="col-lg-1">
                    <v-icon x-large> mdi-arrow-left-bold </v-icon>
                  </div>

                  <div class="col-lg-10">
                    <p>Choose the Category on the left to get started.</p>

                    <span>
                      This is where you build your catalog structure for your
                      partners and store view.
                    </span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card v-if="activatedId[0] !== undefined">
              <v-tabs horizontal>
                <v-tab>
                  <v-icon left>mdi-file</v-icon>
                  Edit
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-file</v-icon>
                  Products
                </v-tab>
                <v-tab-item>
                  <v-toolbar flat light>
                    <v-toolbar-title>Edit Category </v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" light @click="addCategoryBtn">
                      Add SubCategory
                    </v-btn>
                    <v-btn
                      v-if="!editform.isRoot"
                      class="ml-2"
                      @click="removeCategoryBtn"
                    >
                      Delete
                    </v-btn>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editform.name"
                            label="Category Name"
                            :rules="[rules.required]"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-checkbox
                            v-model="editform.published"
                            label="Use Published items Only"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-row>
                            <v-col cols="10">
                              <v-autocomplete
                                v-model="edited_assortment"
                                :items="assortments"
                                item-text="Name"
                                item-value="id"
                                label="Link Assortment"
                                required
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                              <v-spacer></v-spacer>
                              <a
                                class="btn btn-warning mt-3 ml-4"
                                @click="edited_assortment = null"
                                >Delete</a
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                        <!-- <v-card-text v-if="0">
                          OR use Product Rule
                        </v-card-text>
                        <v-col cols="12" sm="12" v-if="0">
                          <v-combobox
                            v-model="editchips"
                            :items="tagNames"
                            chips
                            clearable
                            :search-input.sync="editchipsearch"
                            @change="editchipsearch = ''"
                            label="Enter Tags followed by Enter"
                            multiple
                            solo
                          >
                            <template
                              v-slot:selection="{
                                attrs,
                                item,
                                select,
                                selected
                              }"
                            >
                              <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                @click="select"
                                @click:close="removeEditChip(item)"
                              >
                                <strong>{{ item }}</strong
                                >&nbsp;
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col> -->
                      </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="primary"
                      dark
                      type="submit"
                      :disabled="!editformvalid"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <v-tab-item>
                  <v-row v-if="category_assortment_id" dense class="mt-4 mx-4">
                    <v-col
                      v-for="(item, index) in assortmentproducts"
                      :key="index"
                      :cols="4"
                    >
                      <v-card v-if="item != null" class="h-100 mt-3 mb-3">
                        <v-img
                          :src="
                            item.main_image != null
                              ? baseurl + item.main_image.url
                              : '/media/product-channel.jpg'
                          "
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="200px"
                        >
                        </v-img>
                        <v-card-text class="text--primary">
                          {{ item.name }}
                        </v-card-text>

                        <v-card-actions>
                          Product : {{ item.part_id }}
                          <v-spacer> </v-spacer>
                          <v-btn
                            @click="
                              $router.push('/pim/product-detail/' + item.id)
                            "
                          >
                            Edit
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <div class="row">
                    <b-pagination
                      v-model="productcurrentPage"
                      :total-rows="producttotalCount"
                      :per-page="productlimit"
                      style="margin: 30px auto;"
                    ></b-pagination>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-form>
        </div>
        <!-- Commented to allow users access based off of aplication, not channel temporarily -->
        <!-- <div class="col-lg-12">
          <v-card class="mx-auto mt-5">
            <v-toolbar flat color="indigo" dark>
              <v-toolbar-title>Companies Allowed Access</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="$refs.search.focus()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-toolbar>

            <v-row>
              <v-col cols="4">
                <v-container class="py-0">
                  <v-row align="center" justify="start">
                    <v-col cols="12">
                      <v-autocomplete
                        ref="search"
                        v-model="sellerId"
                        :items="sellers"
                        item-text="company_name"
                        item-value="id"
                        full-width
                        hide-details
                        label="Search Available Companies"
                      ></v-autocomplete>
                      <v-btn dark class="mt-8 float-right" @click="addCompany">
                        Add company
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="8">
                <b-table
                  striped
                  hover
                  :items="allowedSellerList"
                  :fields="fields"
                >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template v-slot:cell(name)="data">
                    {{ data.item.seller_company.company_name }}
                  </template>
                  <template v-slot:cell(actions)="data">
                    <v-btn
                      class="ma-2"
                      tile
                      outlined
                      color="success"
                      @click="
                        removeAccess(
                          data.item.id,
                          data.item.channel.id,
                          data.item.seller_company.id
                        )
                      "
                    >
                      Remove
                    </v-btn>
                  </template>
                </b-table>
                <div class="row">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="limit"
                    style="margin: 0 auto;"
                  ></b-pagination>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!selected.length"
                :loading="loading"
                color="purple"
                text
                @click="next"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card>
        </div> -->
      </div>

      <v-dialog v-model="addDialog" persistent max-width="600px">
        <v-form v-model="addDialogformvalid" @submit.prevent="addCategory">
          <v-card>
            <v-card-title>
              <span class="headline">Add New Category</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="addDialogform.name"
                      label="Category Name"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <!-- <v-select
                    :items="['No Parent', 'Shirts', 'Pants', 'Hats', 'Coats']"
                    label="Parent Category"
                    required
                  ></v-select> -->

                    <v-checkbox
                      v-model="addDialogform.published"
                      label="Use Published items Only"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="added_assortment"
                      :items="assortments"
                      item-text="Name"
                      item-value="id"
                      label="Link Assortment"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-card-text> OR use Product Rule </v-card-text>
                  <v-col cols="12" sm="12">
                    <v-combobox
                      v-model="addchips"
                      :items="tagNames"
                      chips
                      clearable
                      :search-input.sync="addchipsearch"
                      label="Enter Assortment Tags followed by Enter"
                      multiple
                      solo
                      @change="addchipsearch = ''"
                    >
                      <template
                        #selection="{
                          attrs,
                          item,
                          select,
                          parent,
                        }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="parent.selectItem(item)"
                        >
                          <strong>{{ item }}</strong
                          >&nbsp;
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="addDialogClose">
                Close
              </v-btn>
              <v-btn
                type="submit"
                :disabled="!addDialogformvalid"
                color="blue darken-1"
                text
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import AssortmentProducts from '@/graphql/queries/AssortmentProducts.gql'
import GetCompanyTags from '@/graphql/queries/GetCompanyTags.gql'
import CreateFullCategory from '@/graphql/mutations/CreateFullCategory.gql'
import AllowedSellerList from '@/graphql/queries/AllowedSellerList.gql'
import GetCategoriesByChannel from '@/graphql/queries/GetCategoriesByChannel.gql'
import GetChannel from '@/graphql/queries/GetChannel.gql'
import GetCategory from '@/graphql/queries/GetCategory.gql'
import GetChannelsCount from '@/graphql/queries/GetChannelsCount.gql'
import GetAssortmentsProductsCount from '@/graphql/queries/GetAssortmentsProductsCount.gql'
export default {
  data: () => ({
    addchipsearch: '',
    // editchipsearch: "",
    baseurl: '',
    productlimit: 4,
    productcurrentPage: 1,
    producttotalCount: 0,
    limit: 10,
    currentPage: 1,
    totalCount: 0,
    channelName: '',
    tags: [],
    added_assortment: null,
    edited_assortment: null,
    assortments: [],
    addchips: [],
    // editchips: [],
    allowedSellers: [],
    sellerId: '',
    sellers: [],
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    addDialogformvalid: false,
    addDialog: false,
    addDialogform: {
      name: '',
      published: false,
    },
    editformvalid: false,
    editform: {
      name: '',
      published: false,
      isRoot: false,
    },
    activatedId: [],
    loading: false,
    search: '',
    selected: [],
    fields: [
      {
        key: 'index',
        label: '#',
      },
      {
        key: 'name',
        label: 'Company Name',
      },
      {
        key: 'actions',
        label: 'Actions',
      },
    ],
    category_assortment_id: null,
  }),
  computed: {
    tagNames: function() {
      const tagHeader = [{ header: 'Select an option or create one' }]
      const allTags = this.tags ? this.tags.map((t) => t.name) : []
      return [].concat(tagHeader, allTags)
    },
    items() {
      if (this.categories) {
        const items = this.list_to_tree(this.categories)
        return items
      } else {
        return []
      }
    },
  },
  apollo: {
    tags: {
      query: GetCompanyTags,
      variables() {
        return {
          companyId: this.$store.state.auth.user.company_relation,
        }
      },
    },
    assortmentproducts: {
      query: AssortmentProducts,
      skip() {
        return !this.category_assortment_id
      },
      variables() {
        return {
          assortment: this.category_assortment_id,
          limit: this.productlimit,
          start: (this.productcurrentPage - 1) * this.productlimit,
        }
      },
    },
    allowedSellerList: {
      query: AllowedSellerList,
      variables() {
        return {
          channel: parseInt(this.$route.params.id),
          limit: this.limit,
          start: (this.currentPage - 1) * this.limit,
        }
      },
    },
    categories: {
      query: GetCategoriesByChannel,
      variables() {
        return {
          channelId: this.$route.params.id,
        }
      },
    },
    channel: {
      query: GetChannel,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
    },
  },
  created() {
    if (this.$route.params.id != '' && this.$route.params.id != undefined) {
      setTimeout(() => {
        this.$apollo
          .query({
            query: GetChannelsCount,
            variables: {
              channelId: this.$route.params.id,
              supplierId: this.$store.state.auth.user.company_relation,
            },
          })
          .then((res) => {
            if (res.data.channelsCount === 0) {
              this.$router.push('/pim/channels')
            }
          })

        // this.getChannelById({
        //   id: this.$route.params.id,
        // }).then((res) => {
        //   this.channelName = res.data.name
        // })
        // this.getCategoryList()

        // available sellers
        this.getSellerLists()
        this.$apollo.queries.assortmentproducts.refetch()
        this.$apollo.queries.allowedSellerList.refetch()
        this.getTotalCount()

        this.getAssortmentsBySupplier({
          supplier: this.$store.state.auth.user.company_relation,
        }).then((res) => {
          this.assortments = res.data
        })
      }, 1000)
    }
  },
  methods: {
    ...mapActions([
      // 'getCategories',
      'removeCategory',
      // 'getChannelById',
      // 'getCategoryById',
      'updateCategory',
      'getChannelAccessTotalCount',
      'submitChannelAccess',
      'getChannelAccessByChannel',
      'removeChannelAccess',
      'getAssortmentsBySupplier',
      'getAllSellerApplications',
      // 'getAssortmentProductTotalCount',
      // 'getChannelTotalCount',
    ]),
    getTotalCount() {
      this.getChannelAccessTotalCount({
        params: 'channel=' + this.$route.params.id,
      }).then((res) => {
        this.totalCount = res.data
      })
    },
    getSellerLists() {
      this.sellers = []
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.getChannelAccessByChannel({
          channel: this.$route.params.id,
        }).then((resl) => {
          this.allowedSellers = resl.data

          this.getAllSellerApplications({
            supplier_company: this.$store.state.auth.user.company_relation,
          }).then((res) => {
            for (let i in res.data) {
              let flag = 1
              for (let j in this.allowedSellers) {
                if (
                  res.data[i].seller_company.id ==
                  this.allowedSellers[j].seller_company.id
                ) {
                  flag = 0
                }
              }
              if (flag) {
                this.sellers.push({
                  id: res.data[i].seller_company.id,
                  company_name: res.data[i].seller_company.company_name,
                })
              }
            }
          })
        })
      }
    },
    addDialogClose() {
      this.addDialogform.name = ''
      this.addDialogform.published = false
      this.added_assortment = null
      this.addchips = []
      this.addDialog = false
    },
    removeAccess(id) {
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        this.$bvModal
          .msgBoxConfirm('Are you sure?', {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value == true) {
              this.removeChannelAccess({
                id: id,
              })
                .then(() => {
                  this.sellerId = ''

                  //async ecom data
                  // this.$apollo.mutate({
                  //   client: 'ecomClient',
                  //   mutation: gql`
                  //       mutation RemoveCompanyAccessMutation($channel_id: Int!, $company_id: Int!) {
                  //         removeCompanyAccess(
                  //           channel_id: $channel_id
                  //           company_id: $company_id
                  //         )
                  //       }
                  //     `
                  //   ,
                  //   variables: {
                  //     channel_id: parseInt(channel_id),
                  //     company_id: parseInt(company_id),
                  //   },
                  // });

                  Swal.fire({
                    title: '',
                    text: 'The company has been removed from access list.',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                  this.getSellerLists()
                  this.$apollo.queries.allowedSellerList.refetch()
                  this.getTotalCount()
                })
                .catch(() => {
                  this.$bvToast.toast('An Error Occured!', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    appendToast: true,
                  })
                })
            }
          })
      }
    },
    addCompany() {
      if (this.sellerId != '' && this.sellerId != null) {
        if (this.$route.params.id != '' && this.$route.params.id != undefined) {
          this.getChannelAccessTotalCount({
            params:
              'channel=' +
              this.$route.params.id +
              '&seller_company=' +
              this.sellerId,
          }).then((res) => {
            if (res.data == 0) {
              this.submitChannelAccess({
                channel: this.$route.params.id,
                seller_company: this.sellerId,
              })
                .then(() => {
                  Swal.fire({
                    title: '',
                    text: 'The company has been allowed!',
                    icon: 'success',
                    confirmButtonClass: 'btn btn-secondary',
                  })
                  this.getSellerLists()
                  this.$apollo.queries.allowedSellerList.refetch()
                  this.getTotalCount()
                })
                .catch(() => {
                  this.$bvToast.toast('An Error Occured!', {
                    title: 'Error',
                    variant: 'danger',
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    appendToast: true,
                  })
                })
            } else {
              this.$bvToast.toast('This company is already allowed.', {
                title: 'Error',
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
                solid: true,
                appendToast: true,
              })
            }
          })
        }
      }
    },
    // getCategoryList() {
    //   if (this.$route.params.id != '' && this.$route.params.id != undefined) {
    //     this.getCategories({
    //       channel: this.$route.params.id,
    //     }).then((res) => {
    //       this.items = this.list_to_tree(res.data)
    //     })
    //   }
    // },
    list_to_tree(list) {
      var map = {}
      var node
      var roots = []
      var i
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i // initialize the map
        list[i].children = [] // initialize the children
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i]
        if (node.parent && node.parent.id !== 0 && node.parent.id !== '0') {
          // if you have dangling branches check that map[node.parent_id] exists
          if (list[map[node.parent.id]] != undefined)
            list[map[node.parent.id]].children.push(node)
        } else {
          roots.push(node)
        }
      }
      return roots
    },
    next() {
      this.loading = true

      setTimeout(() => {
        this.search = ''
        this.selected = []
        this.loading = false
      }, 2000)
    },
    removeCategoryBtn() {
      if (this.activatedId[0] !== undefined) {
        this.$bvModal
          .msgBoxConfirm(
            'This action can not be undone. Are you sure to continue?',
            {
              title: 'Confirm Deletion',
              size: 'sm',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value == true) {
              this.removeCategory({
                id: this.activatedId[0],
              }).then(() => {
                this.activatedId = []
                this.editform.name = ''
                this.editform.published = false
                this.edited_assortment = null
                // this.editchips = [];

                Swal.fire({
                  title: '',
                  text: 'The category has been successfully removed!',
                  icon: 'success',
                  confirmButtonClass: 'btn btn-secondary',
                })
                this.$apollo.queries.categories.refetch()
                // this.getCategoryList()
              })
            }
          })
      } else {
        this.$bvToast.toast('Please select the category to remove.', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
    addCategoryBtn() {
      this.addDialog = true
    },
    addCategory() {
      let pid = 0
      if (this.activatedId[0] !== undefined) {
        pid = this.activatedId[0]
      }
      if (this.$route.params.id != '' && this.$route.params.id != undefined) {
        const newCategory = {
          name: this.addDialogform.name,
          parent: pid,
          published: this.addDialogform.published,
          channel: this.$route.params.id,
          assortment: this.added_assortment,
        }
        this.$apollo
          .mutate({
            mutation: CreateFullCategory,
            variables: { newCategory, searchTags: this.addchips },
          })
          .then(() => {
            this.addDialog = false
            this.addDialogform.name = ''
            this.addDialogform.published = false
            this.added_assortment = null
            this.addchips = []
            // refreshes tags incase a new one was created
            this.$apollo.queries.tags.refetch()

            Swal.fire({
              title: '',
              text: 'The category has been successfully created!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
            this.$apollo.queries.categories.refetch()
            // this.getCategoryList()
          })
          .catch(() => {
            this.$bvToast.toast('An Error Occured!', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: true,
            })
          })
      }
    },
    editCategory() {
      if (this.activatedId[0] !== undefined) {
        this.updateCategory({
          id: this.activatedId[0],
          name: this.editform.name,
          published: this.editform.published,
          assortment: this.edited_assortment,
        })
          .then(() => {
            if (this.edited_assortment != null) {
              this.category_assortment_id = this.edited_assortment

              this.producttotalCount = 0
              this.$apollo
                .query({
                  query: GetAssortmentsProductsCount,
                  variables: { assortmentId: this.category_assortment_id },
                })
                .then((res) => {
                  this.producttotalCount = res.data
                })
            } else {
              this.category_assortment_id = null
            }

            Swal.fire({
              title: '',
              text: 'The category has been successfully updated!',
              icon: 'success',
              confirmButtonClass: 'btn btn-secondary',
            })
            this.$apollo.queries.categories.refetch()
            // this.getCategoryList()
          })
          .catch(() => {
            this.$bvToast.toast('An Error Occured!', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: true,
            })
          })
      }
    },
    onUpdate(tree) {
      this.activatedId = tree
      if (this.activatedId[0] !== undefined) {
        this.$apollo
          .query({
            query: GetCategory,
            variables: { id: this.activatedId[0] },
          })
          .then((res) => {
            this.editform.name = res.data.category.name
            this.editform.published = res.data.category.published
            this.edited_assortment = res.data.category.assortment

            if (res.data.category.assortment != null) {
              this.category_assortment_id = res.data.category.assortment.id

              this.producttotalCount = 0
              this.$apollo
                .query({
                  query: GetAssortmentsProductsCount,
                  variables: { assortmentId: res.data.category.assortment.id },
                })
                .then((res) => {
                  this.producttotalCount = res.data.productsCount
                })
            } else {
              this.category_assortment_id = null
            }

            if (res.data.category.parent == null) {
              this.editform.isRoot = true
            } else {
              this.editform.isRoot = false
            }
          })
      } else {
        this.producttotalCount = 0
        this.editform.name = ''
        this.editform.published = false
        this.category_assortment_id = null
      }
    },
  },
}
</script>
